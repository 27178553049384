<template>
  <TemplateAdmin :loading="loading_app">
    <template slot="container">

      <h2 class="page-title">Fale Conosco</h2>

      <div class="user-page">
        <section class="user-data">
          <Card class="edit-data">
            <div class="personal-data">
              <form ref="form">
                <CustomInput type="text" name="name" id="name" placeholder="Seu nome"
                title="Nome completo" v-model="name" :maxLength="'80'"/>
                <div class="row-data">
                  <CustomInput type="text" name="phone" id="phone" placeholder="Digite seu telefone"
                  title="Telefone" v-model="phone" @input="onPhoneInput" :maxLength="'17'" />

                  <CustomInput type="email" name="email" id="email" placeholder="Seu melhor email"
                  title="Email*" v-model="email" :maxLength="'80'"/>
                </div>

                  <CustomInput type="text" name="message" id="message"
                    placeholder="Sua mensagem"
                    title="Mensagem" v-model="message" :textArea="true" :maxLength="'800'" />
              </form>
            </div>
          </Card>
        </section>
      </div>

      <div class="save-data">
        <PdcButton class="button" variant="primary" id="button-discovery" @click.native="sendEmail"
          :loading="loadingSave">Enviar</PdcButton>
      </div>
    </template>

  </TemplateAdmin>
</template>

<script>
import { mapGetters } from 'vuex';
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
import Card from '@/components/atoms/Card/Card.vue';
import CustomInput from '@/components/atoms/CustomInput/CustomInput.vue';
import PdcButton from '@/components/atoms/Button/Button.vue';
import CONSTANTS from '@/utils/constants';
import {
  sendMail,
} from '@/services/send-mail.service';

export default {
  name: 'Contact',
  components: {
    TemplateAdmin,
    Card,
    CustomInput,
    PdcButton,
  },
  data() {
    return {
      name: '',
      email: '',
      message: '',
      phone: '',
      loadingSave: false,
    };
  },
  computed: {
    ...mapGetters('profile', ['toastMessage']),
    ...mapGetters('application', ['loading_app', 'route', 'tenantConfig']),
    ...mapGetters('auth', ['accountComplete', 'account']),
    statesToShow() {
      const states = CONSTANTS?.data?.STATES;
      const statesArray = Object.entries(states).map(([sigla, nome]) => ({
        label: nome,
        value: sigla,
      }));
      return [...statesArray];
    },

  },
  methods: {
    async sendEmail() {
      try {
        this.loadingSave = true;
        const mailObject = {
          userName: this.name,
          userPhone: this.phone.replace(/\D/g, ''),
          userEmail: this.email,
          message: this.message,
        };

        await sendMail(mailObject);

        this.message = '';

        this.$swal.fire({
          title: 'Sucesso ao enviar mensagem.',
          text: 'Sua mensagem foi enviada com sucesso!',
          icon: 'success',
        });
      } catch (e) {
        this.$swal.fire({
          title: 'Falha ao enviar mensagem.',
          text: 'Houve um erro ao enviar sua mensagem! Por favor tente novamente e não se esqueça de preencher todos os campos.',
          icon: 'error',
        });
        throw new Error(e);
      } finally {
        this.loadingSave = false;
      }
    },
    formatPhoneNumber(phoneNumber) {
      const cleaned = phoneNumber.replace(/\D/g, '').slice(0, 17);

      if (cleaned.length === 11) {
        return cleaned.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
      } else if (cleaned.length === 10) {
        return cleaned.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
      } else if (cleaned.length === 8) {
        return cleaned.replace(/^(\d{4})(\d{4})$/, '$1-$2');
      } else if (cleaned.length === 13) {
        return cleaned.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+$1($2) $3-$4');
      }
      return cleaned;
    },
    onPhoneInput(event) {
      const formattedPhone = this.formatPhoneNumber(event);
      this.phone = formattedPhone;
    },
  },
  async mounted() {
    this.name = this.accountComplete.name;
    this.phone = this.formatPhoneNumber(this.accountComplete.phone);
    this.email = this.account.email;
  },
};
</script>

<style lang="scss">
.page-title {
  padding: 0 1.3rem;
}

.user-page {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 2.5rem;

  .user-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .edit-data {
      padding-bottom: 1rem;
    }

    h2 {
      width: 100%;
      color: #3E297F;
    }

  }
}

h3 {
  margin: 0.5rem 0;
  color: #3E297F;
}

h2 {
  color: #3E297F;
}

.personal-data {
  display: flex;
  flex-direction: column;

  .row-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 2rem;

    .form-group {
      width: 100%;
    }
  }
}

Card {
  border-radius: 30px;
}

.save-data {
  display: flex;
  padding: 0;
  margin: 0;
  //width:48%;
  height: 5rem;
  justify-content: flex-end;
  align-items: center;
  // margin-top: 3rem;

  button {
    padding: 10px 43px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    border: 1px solid #3E297F;
    color: #3E297F;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    background: white;
    font-weight: 700;
    line-height: 144%;
    cursor: pointer;

    &:hover {
      background-color: #3E297F;
      color: #FFF;
      border-color: #3E297F;
      transition: ease 0.4s;
    }
  }
}

@media (max-width: 430px) {
  .user-page {
    display: grid;
    grid-template-columns: 100vw;
    grid-auto-rows: auto;
    //grid-auto-columns:auto;
  }

  .user-page .user-data {
    width: 90%;
  }
}

</style>
