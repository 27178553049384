var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[(_vm.title)?_c('label',{attrs:{"for":_vm.id}},[(_vm.hasIcon)?_c('img',{attrs:{"src":_vm.icon,"alt":""}}):_vm._e(),_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('div',{staticClass:"custom-input"},[(_vm.$slots.iconStart)?_c('div',{staticClass:"icon icon-start"},[_vm._t("iconStart")],2):_vm._e(),(_vm.textArea)?_c('textarea',{class:{
      hasError: _vm.error, iconStart: _vm.$slots.iconStart,
      iconEnd: _vm.$slots.iconEnd,
      textAreaStyle: _vm.textArea,
      'poppins-font': true
    },attrs:{"disabled":_vm.loading,"type":_vm.type,"id":_vm.id,"placeholder":_vm.placeholder,"name":_vm.name,"maxLength":_vm.maxLength},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"change":_vm.onChange}}):_c('input',{class:{
      hasError: _vm.error, iconStart: _vm.$slots.iconStart,
      iconEnd: _vm.$slots.iconEnd
    },attrs:{"disabled":_vm.loading,"type":_vm.type,"id":_vm.id,"placeholder":_vm.placeholder,"name":_vm.name,"maxLength":_vm.maxLength},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"change":_vm.onChange}}),(_vm.$slots.iconEnd)?_c('div',{staticClass:"icon icon-end"},[_vm._t("iconEnd")],2):_vm._e()]),(_vm.helperText)?_c('small',[_vm._v(_vm._s(_vm.helperText))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }