<template>
  <div class="form-group">
    <label :for="id" v-if="title">
      <img v-if="hasIcon" :src="icon" alt="">{{ title }}</label>
    <div class="custom-input">
      <div class="icon icon-start" v-if="$slots.iconStart">
        <slot name="iconStart" />
      </div>
      <textarea v-if="textArea" :disabled="loading" :type="type" :id="id" :class="{
        hasError: error, iconStart: $slots.iconStart,
        iconEnd: $slots.iconEnd,
        textAreaStyle: textArea,
        'poppins-font': true
      }"
        :placeholder="placeholder"
        :name="name" :value="value" @input="onInput" @change="onChange"
        :maxLength="maxLength" />
      <input v-else :disabled="loading" :type="type" :id="id" :class="{
        hasError: error, iconStart: $slots.iconStart,
        iconEnd: $slots.iconEnd
      }"
        :placeholder="placeholder"
        :name="name" :value="value" @input="onInput" @change="onChange"
        :maxLength="maxLength" />
      <div class="icon icon-end" v-if="$slots.iconEnd">
        <slot name="iconEnd" />
      </div>
    </div>
    <small v-if="helperText">{{ helperText }}</small>
  </div>
</template>

<script>

export default {
  name: 'CustomInput',
  props: {
    title: {
      type: String,
      required: false,
    },
    helperText: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: false,
    },
    hasIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxLength: {
      type: String,
      required: false,
    },
    textArea: {
      type: Boolean,
    },
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value);
    },
    onChange(event) {
      this.$emit('change', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}

label {
  color: $color-neutral-secondary;
  font-weight: 500;
  font-size: 1rem;
  margin-left: 0.5rem;
  gap: 0.4rem;
  img {
    height: 1rem;
    // width: 1rem;
    opacity: .7;
  }
}

.custom-input {
  position: relative;

  // display: flex;
  // align-items: center;
  // background-color: #fff;
  // border-radius: 18px;
}

input, textarea {
  box-sizing: border-box;
  border: 0.5px solid #979797;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 18px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  color: $color-neutral-secondary;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:disabled {
    background-color: $color-neutral-light;
    cursor: not-allowed;
  }

  &::placeholder {
    color: $color-neutral-secondary;
    opacity: 0.5;
    text-align: justify;
  }

  &:focus {
    border-color: #979797;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(151, 151, 151, .25);
  }

  &:disabled {
    background-color: #e9ecef;
    opacity: 0.5;
    color: #888;
    cursor: not-allowed;
  }

  &.iconStart {
    padding: 0.375rem 0.75rem 0.375rem 2rem;
  }

  &.iconEnd {
    padding: 0.375rem 2rem 0.375rem 0.75rem;
  }
}

small {
  color: $color-neutral-secondary;
  margin-top: 0.25rem;
  margin-left: 0.5rem;
  font-weight: 400;
}

.hasError {
  &::placeholder {
    color: $color-feedback-error-dark;
  }
  border-color: $color-feedback-error-dark;
}

.icon {
  position: absolute;
  top: 0.375rem;
}

.icon-start {
  left: 0.75rem;
}

.icon-end {
  right: 0.75rem;
}

.textAreaStyle {
  height: 100px;
  resize: none;
  overflow-wrap: break-word;
}

.poppins-font {
  font-family: 'inherit', sans-serif;
  font-size: 1rem;
  font-weight: 200;
  line-height: 1.5rem;
}
</style>
